import type { $Fetch } from "ofetch";
import Echo from 'laravel-echo';

export class UserRepository {
  constructor(private readonly fetch: $Fetch, private readonly echo: Echo) { }

  /**
   * Subscribe to the User channel.
   * @param userId 
   */
  subscribeToUserChannel(userId: string) {
    const userChannelName = `App.Models.User.${userId}`;
    this.echo.private(userChannelName)
      .listen('UserUpdated', (event: any) => {
        console.log('User updated event received for user:', userId, event);
        // Handle user update logic here
      });
  }

  /**
   * Unsubscribe from the User channel.
   */
  dispose(userId: string) {
    const userChannelName = `App.Models.User.${userId}`;
    this.echo.leave(userChannelName);
  }

  /**
   * Update a User.
   * @param params Parameters
   * @return UserData
   */
  async update(userId: string, params?: App.Data.UserData): Promise<App.Data.UserData> {
    return await this.fetch(`/api/v1/users/${userId}`, {
      method: "patch",
      body: params,
    });
  }
}
