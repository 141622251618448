import type { $Fetch } from "ofetch";

/**
 * A paginator that works with Laravel's cursor pagination
 * Optional handling of virtualization of the API response
 * @param fetch
 * @param params
 * @param cursor
 * @param virtualize
 */
export class Paginator<Entity, Params = never>
  implements AsyncIterableIterator<Entity>, PromiseLike<Entity> {
  constructor(
    private readonly fetch: $Fetch,
    private readonly path: string,
    private params?: Params,
    private cursor?: string,
    private readonly virtualize = false,
    private readonly virtualizeOptions?: {
      readonly pageSize?: number;
      readonly initialSize?: number;
      readonly maxSize?: number;
    },
  ) {
    // Initialize the first page path and params
    this.nextPath = path;
    this.nextParams = this.params || ({} as Params);
  }

  private nextPath?: string;
  private nextParams?: Params;

  async next(): Promise<IteratorResult<Entity, undefined>> {
    if (this.nextPath === undefined) {
      return { done: true, value: undefined };
    }

    // Perform the API request with the current cursor and params
    const response = await this.fetch.raw(this.nextPath, {
      method: "get",
      query: {
        ...this.nextParams,
        cursor: this.cursor, // Include the cursor for Laravel's cursor pagination
      } as Record<string, unknown>,
    });

    // Extract the cursor for the next page
    const nextCursor = response._data?.links?.next as string | undefined;

    // Update the next path and cursor for subsequent requests
    this.cursor = nextCursor;
    this.nextPath = nextCursor ? this.nextPath : undefined; // Clear path if no more pages

    return {
      done: !nextCursor,
      value: response._data.data as Entity,
    };
  }

  async return(
    value?: undefined | Promise<undefined>,
  ): Promise<IteratorResult<Entity, undefined>> {
    this.clear();
    return {
      done: true,
      value: await value,
    };
  }

  async throw(e: unknown): Promise<IteratorResult<Entity, undefined>> {
    this.clear();
    throw e;
  }

  then<TResult1 = Entity, TResult2 = never>(
    onfulfilled: (
      value: Entity,
    ) => TResult1 | PromiseLike<TResult1> = Promise.resolve,
    onrejected: (
      reason: unknown,
    ) => TResult2 | PromiseLike<TResult2> = Promise.reject,
  ): Promise<TResult1 | TResult2> {
    // Assume the first item won't be undefined
    return this.next().then((value) => onfulfilled(value.value!), onrejected);
  }

  [Symbol.asyncIterator](): AsyncGenerator<
    Entity,
    undefined,
    Params | undefined
  > {
    return this;
  }

  private clear() {
    this.nextPath = undefined;
    this.nextParams = undefined;
  }

  clone(): Paginator<Entity, Params> {
    return new Paginator(this.fetch, this.nextParams, this.cursor);
  }
}
