import type { $Fetch } from "ofetch";
import Echo from 'laravel-echo';
import { Paginator } from "~/api/paginator";
import type { DefaultPaginationParams } from "~/api/repository";

export class TempGameRepository {
  constructor(private readonly fetch: $Fetch, private readonly echo: Echo) { }

  /**
   * Subscribe to the scores channel for a specific game.
   */
  subscribeToScoresForTempGameChannel(gameId: string, callback: (event: any) => void) {
    return this.echo.channel('temp-games.' + gameId)
      .listen('.TempGameUpdated', (event: any) => {
        callback(event);
      });
  }

  /**
   * List all Games.
   * 
   */
  list(): Paginator<Modules.Game.Data.GameData[], DefaultPaginationParams> {
    return new Paginator<Modules.Game.Data.GameData[], DefaultPaginationParams>(this.fetch, '/api/v1/temp-games');
  }

  /** 
   * Create a temp game
   */
  async create(params?: any): Promise<Modules.Game.Data.GameData> {
    const response = await this.fetch(`/api/v1/temp-games`, {
      method: "post",
      body: params,
    });
    return response.data;
  }

  async retrieve(gameId: string) {
    const response = await this.fetch(`/api/v1/temp-games/${gameId}`);
    return response.data;
  }

  /**
   * Update a Game.
   * @param params Parameters
   * @return UserData
   */
  async update(gameId: string, params?: Modules.Game.Data.GameData): Promise<Modules.Game.Data.GameData> {
    return await this.fetch(`/api/v1/temp-games/${gameId}`, {
      method: "patch",
      body: params,
    });
  }

  async increment(gameId: string, teamIndex: number) {
    return await this.fetch(`/api/v1/temp-games/${gameId}/increment`, {
      method: "post",
      body: { teamIndex: teamIndex },
    });
  }

  async decrement(gameId: string, teamIndex: number) {
    return await this.fetch(`/api/v1/temp-games/${gameId}/decrement`, {
      method: "post",
      body: { teamIndex: teamIndex },
    });
  }

  async start(gameId: string) {
    return await this.fetch(`/api/v1/temp-games/${gameId}/start`, {
      method: "post",
    });
  }

  async play(gameId: string) {
    return await this.fetch(`/api/v1/temp-games/${gameId}/play`, {
      method: "post",
    });
  }

  async pause(gameId: string, remainingTime: { remaining_minutes: number; remaining_seconds: number }) {
    return await this.fetch(`/api/v1/temp-games/${gameId}/pause`, {
      method: "post",
      body: {
        remaining_minutes: remainingTime.remaining_minutes,
        remaining_seconds: remainingTime.remaining_seconds,
      },
    });
  }

  async syncClock() {
    return await this.fetch(`/api/v1/temp-games/sync-clock`);
  }

  /**
   * Delete a Game.
   * @param gameId 
   */
  async delete(gameId: string) {
    return await this.fetch(`/api/v1/temp-games/${gameId}`, {
      method: "delete",
    });
  }
}
