import type { $Fetch } from "ofetch";
import { AggregateRepository as V1AggregateRepository } from "./v1";

export class Client {
  readonly v1: V1AggregateRepository;

  constructor(readonly fetch: $Fetch, readonly echo: any) {
    this.v1 = new V1AggregateRepository(fetch, echo);
  }
}
