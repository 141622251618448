import revive_payload_client_6rl6tPLtIb from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_oLBX1C3oH9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HhRmoq0bu1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_h7DZsfWDva from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_WVNdHFmtZd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dXb1pdk0Xl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_BJrp4R6nb2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tPasW3ABQ4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_s7ONPaycw9 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_JvMeGhxCMj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.13.0_rollup@4.24.0_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_IfcldbFAwz from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_rzev2x9c91 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_gIwg1V2PWs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_tH7fYnZj6q from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ugLB9oeJqq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_miGlWcbtBl from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.24.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_JJ8RLYxal9 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_YFEos94tQp from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_scXOBn78Cs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_VPkW2eZXiw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.24.0_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import client_8y0XQRGs9x from "/opt/buildhome/repo/src/api/plugins/client.ts";
import echo_HksZXjvotB from "/opt/buildhome/repo/src/api/plugins/echo.ts";
import _1_scroll_to_top_WX31OCL1jk from "/opt/buildhome/repo/src/plugins/1.scroll-to-top.ts";
import hydration_client_7uir9Lpz2B from "/opt/buildhome/repo/src/plugins/hydration.client.ts";
import sonner_client_cxteQUnCKH from "/opt/buildhome/repo/src/plugins/sonner.client.ts";
import vue_query_JC9gnf7CA1 from "/opt/buildhome/repo/src/plugins/vue-query.ts";
export default [
  revive_payload_client_6rl6tPLtIb,
  unhead_oLBX1C3oH9,
  router_HhRmoq0bu1,
  _0_siteConfig_h7DZsfWDva,
  payload_client_WVNdHFmtZd,
  navigation_repaint_client_dXb1pdk0Xl,
  check_outdated_build_client_BJrp4R6nb2,
  chunk_reload_client_tPasW3ABQ4,
  plugin_vue3_s7ONPaycw9,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JvMeGhxCMj,
  switch_locale_path_ssr_IfcldbFAwz,
  i18n_rzev2x9c91,
  slideovers_gIwg1V2PWs,
  modals_tH7fYnZj6q,
  colors_ugLB9oeJqq,
  plugin_client_miGlWcbtBl,
  plugin_JJ8RLYxal9,
  scrollbars_client_YFEos94tQp,
  presets_scXOBn78Cs,
  variables_VPkW2eZXiw,
  client_8y0XQRGs9x,
  echo_HksZXjvotB,
  _1_scroll_to_top_WX31OCL1jk,
  hydration_client_7uir9Lpz2B,
  sonner_client_cxteQUnCKH,
  vue_query_JC9gnf7CA1
]