import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
    interface Window {
        Pusher: any;
        Echo: any;
    }
}


export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const apiConfig = config.public.api;
    const wsConfig = config.public.ws;

    if (import.meta.client) {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            cluster: 'ap1',
            key: wsConfig.key,
            wsHost: wsConfig.host,
            wsPort: wsConfig.port,
            wssPort: wsConfig.port,
            disableStats: true,
            forceTLS: import.meta.env.PROD,
            encrypted: false,
            enabledTransports: ['ws'],
            authorizer: (channel: any, options: any) => {
                return {
                    authorize: (socketId: any, callback: any) => {
                        $fetch('/api/v1/broadcasting/auth', {
                            baseURL: apiConfig.baseUrl,
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                Accept: 'application/json',
                            },
                            retry: false,
                            body: {
                                socket_id: socketId,
                                channel_name: channel.name,
                            },

                            async onResponse({ response }: { response: any }) {
                                callback(null, response._data);
                            },

                            async onError({ response }: { response: any }): Promise<void> {
                                callback(response, null);
                            },
                        });
                    }
                }
            }
        });
    }
});