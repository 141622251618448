<template>
    <div class="@container flex min-h-screen flex-1">
        <div class="@lg:flex-none flex flex-1 flex-col justify-center px-4 py-12 @sm:px-6 @lg:px-20 @xl:px-24">
            <div class="mx-auto w-full max-w-sm @lg:w-96">
                <div>
                    <RouterLink to="/">
                        <CommonLogo class="h-6 w-auto" />
                    </RouterLink>
                    <div
                        class="mt-8 flex flex-col text-4xl font-bold leading-9 tracking-tight dark:text-white text-gray-900"
                    >
                        <template v-if="error.statusCode == 404">
                            <span class="text-gray-400">404</span>
                            <span class="text-5xl">Page not found</span>

                            <p class="mt-7 text-sm">Sorry, but the page you were looking for could not be found.</p>

                            <span class="text-sm font-light mt-4"
                                >You can return to our
                                <a @click="handleError" class="link cursor-pointer">home page </a> or get help.</span
                            >
                        </template>

                        <template v-if="error.statusCode == 403">
                            <span class="text-gray-400">403</span>
                            <span class="text-5xl">Forbidden</span>

                            <p class="mt-7 font-light text-base">
                                Sorry you don't have permission to access this page. Visit our
                                <a @click="handleError" class="link cursor-pointer">home page </a>
                                or get help.
                            </p>
                        </template>

                        <template v-else>
                            <template v-if="error">
                                <div class="py-2 font-light text-xs">
                                    {{ error }}
                                </div>

                                <span class="mt-7 text-sm">
                                    Sorry there was a technical issue. Visit our
                                    <a @click="handleError" class="text-blue-500 cursor-pointer">home page </a>
                                    or get help.
                                </span>
                            </template>
                            <template v-else>
                                Sorry something went wrong
                                <span class="mt-7 text-sm">
                                    Sorry there was a technical issue. Visit our
                                    <a @click="handleError" class="text-blue-500 cursor-pointer">home page </a>
                                    or get help.
                                </span>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <EyeCandySection />
    </div>
</template>

<script setup>
definePageMeta({
    layout: 'none',
});

const props = defineProps({
    error: Object,
});

const handleError = () => clearError({ redirect: '/' });
</script>

<style lang="scss" scoped></style>
