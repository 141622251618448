import type { $Fetch } from "ofetch";
import Echo from 'laravel-echo';
import { Paginator } from "~/api/paginator";
import type { DefaultPaginationParams } from "~/api/repository";

export class GameRepository {
  constructor(private readonly fetch: $Fetch, private readonly echo: Echo) { }

  /**
   * Subscribe to the scores channel for a specific game.
   */
  subscribeToScoresForGameChannel(gameId: number, callback: (event: any) => void) {
    this.echo.channel('game.' + gameId)
      .listen('.ScoreUpdated', (event: any) => {
        callback(event);
      });
  }

  /**
   * List all Games.
   * 
   */
  list(): Paginator<Modules.Game.Data.GameData[], DefaultPaginationParams> {
    return new Paginator<Modules.Game.Data.GameData[], DefaultPaginationParams>(this.fetch, '/api/v1/games');
  }

  /**
   * Update a Game.
   * @param params Parameters
   * @return UserData
   */
  async update(gameId: number, params?: Modules.Game.Data.GameData): Promise<Modules.Game.Data.GameData> {
    return await this.fetch(`/api/v1/games/${gameId}`, {
      method: "patch",
      body: params,
    });
  }

  /**
   * Delete a Game.
   * @param gameId 
   */
  async delete(gameId: number) {
    return await this.fetch(`/api/v1/games/${gameId}`, {
      method: "delete",
    });
  }
}
