import type { $Fetch } from "ofetch";
import type { DefaultPaginationParams } from "../repository";
import Echo from 'laravel-echo';

import {
  AuthRepository,
  UserRepository,
  ConfigRepository,
  NotificationRepository,
  TournamentRepository,
  FieldRepository,
  OrganisationRepository,
  OrganisationMemberRepository,
  GameRepository,
  TempGameRepository,
} from "./repositories";

export type SearchType = "games" | "sports" | "organisation";

export interface SearchParams extends DefaultPaginationParams {
  /** Attempt to match this string in the name or description of the game or sport */
  readonly q: string;
  /** Enum(games, sports, organisation) */
  readonly type?: SearchType | null;
  /** If provided, games or sports returned will be authored by this organisation */
  readonly organisationId?: string | null;
}

export class AggregateRepository {
  readonly auth: AuthRepository;
  readonly user: UserRepository;
  readonly config: ConfigRepository;
  readonly organisation: OrganisationRepository;
  readonly organisationMember: OrganisationMemberRepository;
  readonly notifications: NotificationRepository;
  readonly tournament: TournamentRepository;
  readonly field: FieldRepository;
  readonly game: GameRepository;
  readonly tempGame: TempGameRepository;

  constructor(
    private readonly fetch: $Fetch,
    private readonly echo: Echo,
  ) {
    this.auth = new AuthRepository(fetch);
    this.game = new GameRepository(fetch, echo);
    this.tempGame = new TempGameRepository(fetch, echo);
    this.user = new UserRepository(fetch, echo);
    this.config = new ConfigRepository(fetch);
    this.organisation = new OrganisationRepository(fetch);
    this.organisationMember = new OrganisationMemberRepository(fetch);
    this.notifications = new NotificationRepository(fetch, echo);
    this.field = new FieldRepository(fetch);
    this.tournament = new TournamentRepository(fetch);
  }
}
